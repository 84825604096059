<template>
  <div class="empl-group">
    <div class="df-row">
      <div class="df-col">
        <el-date-picker
          v-model="multipleDate"
          size="mini"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
          @change="onChangeDate"
        >
        </el-date-picker>
      </div>
      <div class="df-col">
        <el-button type="primary" size="mini" @click="getEmployeeStatis"
          >查 询</el-button
        >
      </div>
    </div>

    <el-table
      :data="tableList"
      :max-height="tableMaxHeight"
      border
      stripe
      size="mini"
    >
      <el-table-column prop="" label="员工头像">
        <template slot-scope="scope">
          <div class="image">
            <el-image :src="scope.row.EmplHead" fit="fill"></el-image>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="EmplName" label="员工名称"></el-table-column>
      <el-table-column prop="EmplNo" label="员工编号"></el-table-column>
      <el-table-column prop="" label="普通订单数">
        <template slot="header" slot-scope="head">
          <div class="order-count" @click="onChangeSort(6)">
            <span>普通订单数</span>
            <div class="icon-box">
              <!-- <i
                  class="el-icon-caret-top"
                  :class="submitData.sort_value ? '' : 'sort-active'"
                ></i> -->
              <i
                class="el-icon-caret-bottom"
                :class="submitData.sort_value == 6 ? 'sort-active' : ''"
              ></i>
            </div>
          </div>
        </template>
        <template slot-scope="scope">{{ scope.row.NormalOrderCount }}</template>
      </el-table-column>
      <el-table-column prop="" label="普通订单金额">
        <template slot="header" slot-scope="head">
          <div class="order-count" @click="onChangeSort(5)">
            <span>普通订单金额</span>
            <div class="icon-box">
              <!-- <i
                  class="el-icon-caret-top"
                  :class="submitData.sort_value ? '' : 'sort-active'"
                ></i> -->
              <i
                class="el-icon-caret-bottom"
                :class="submitData.sort_value == 5 ? 'sort-active' : ''"
              ></i>
            </div>
          </div>
        </template>
        <template slot-scope="scope">{{
          scope.row.NormalOrderAmount
        }}</template>
      </el-table-column>
      <el-table-column prop="" label="">
        <template slot="header" slot-scope="head">
          <div class="groupon-count" @click="onChangeSort(4)">
            <span>拼团数</span>
            <div class="icon-box">
              <!-- <i
                  class="el-icon-caret-top"
                  :class="submitData.sort_value ? '' : 'sort-active'"
                ></i> -->
              <i
                class="el-icon-caret-bottom"
                :class="submitData.sort_value == 4 ? 'sort-active' : ''"
              ></i>
            </div>
          </div>
        </template>
        <template slot-scope="scope">{{ scope.row.GrouponCount }}</template>
      </el-table-column>
      <el-table-column prop="" label="拼团订单数">
        <template slot="header" slot-scope="head">
          <div class="order-count" @click="onChangeSort(3)">
            <span>拼团订单数</span>
            <div class="icon-box">
              <!-- <i
                  class="el-icon-caret-top"
                  :class="submitData.sort_value ? '' : 'sort-active'"
                ></i> -->
              <i
                class="el-icon-caret-bottom"
                :class="submitData.sort_value == 3 ? 'sort-active' : ''"
              ></i>
            </div>
          </div>
        </template>
        <template slot-scope="scope">{{
          scope.row.GrouponOrderCount
        }}</template>
      </el-table-column>
      <el-table-column prop="" label="拼团订单金额">
        <template slot="header" slot-scope="head">
          <div class="order-count" @click="onChangeSort(2)">
            <span>拼团金额</span>
            <div class="icon-box">
              <!-- <i
                  class="el-icon-caret-top"
                  :class="submitData.sort_value ? '' : 'sort-active'"
                ></i> -->
              <i
                class="el-icon-caret-bottom"
                :class="submitData.sort_value == 2 ? 'sort-active' : ''"
              ></i>
            </div>
          </div>
        </template>
        <template slot-scope="scope">{{
          scope.row.GrouponOrderAmount
        }}</template>
      </el-table-column>
      <el-table-column prop="" label="">
        <template slot="header" slot-scope="head">
          <div class="order-count" @click="onChangeSort(1)">
            <span>总订单数</span>
            <div class="icon-box">
              <!-- <i
                  class="el-icon-caret-top"
                  :class="submitData.sort_value ? '' : 'sort-active'"
                ></i> -->
              <i
                class="el-icon-caret-bottom"
                :class="submitData.sort_value == 1 ? 'sort-active' : ''"
              ></i>
            </div>
          </div>
        </template>
        <template slot-scope="scope">{{ scope.row.TotalOrderCount }}</template>
      </el-table-column>
      <el-table-column prop="" label="">
        <template slot="header" slot-scope="head">
          <div class="order-amount" @click="onChangeSort(0)">
            <span>总金额</span>
            <div class="icon-box">
              <!-- <i
                  class="el-icon-caret-top"
                  :class="submitData.sort_value ? '' : 'sort-active'"
                ></i> -->
              <i
                class="el-icon-caret-bottom"
                :class="submitData.sort_value == 0 ? 'sort-active' : ''"
              ></i>
            </div>
          </div>
        </template>
        <template slot-scope="scope">{{ scope.row.TotalOrderAmount }}</template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import Shop from "@/api/shop.js";

export default {
  data() {
    return {
      tableMaxHeight: "",
      multipleDate: [], // 日期时间选择器
      tableList: [],
      copyTableList: [],
      submitData: {
        begin_date: "",
        end_date: "",
        sort_value: 0,
      },
    };
  },

  created() {
    this.getResize();
  },

  mounted() {
    window.addEventListener("resize", this.getResize);

    this.multipleDate = [
      this.util.getNowMonthToday(),
      this.util.getNowMonthToday(),
    ];
    this.onChangeDate();
    this.getEmployeeStatis();
  },

  destroyed() {
    window.removeEventListener("resize", this.getResize);
  },

  methods: {
    // 获取员工拼团销售统计
    async getEmployeeStatis() {
      try {
        let { data } = await Shop.getEmployeeStatis({
          ...this.submitData,
          // sort_value:
          //   this.submitData.sort_value == -1 ? 0 : this.submitData.sort_value,
        });
        this.tableList = data;
        this.copyTableList = JSON.parse(JSON.stringify(data));
      } catch (err) {
        console.log(err);
      }
    },

    // 获取浏览器窗口大小
    getResize() {
      let { clientHeight } = this.util.getDocumentElementInfo();
      this.tableMaxHeight = clientHeight - (100 + 40 + 68);
    },

    // 日期改变时间
    onChangeDate() {
      this.submitData.begin_date = this.multipleDate[0];
      this.submitData.end_date = this.multipleDate[1];
    },

    // 排序改变事件
    onChangeSort(sort) {
      this.submitData.sort_value =
        this.submitData.sort_value == sort ? -1 : sort;

      if (this.submitData.sort_value === -1) {
        return (this.tableList = JSON.parse(
          JSON.stringify(this.copyTableList)
        ));
      }
      this.tableList = JSON.parse(JSON.stringify(this.copyTableList)).sort(
        (a, b) => {
          switch (this.submitData.sort_value) {
            case 1:
              return b.TotalOrderCount - a.TotalOrderCount;
              break;
            case 2:
              return b.GrouponOrderAmount - a.GrouponOrderAmount;
              break;
            case 3:
              return b.GrouponOrderCount - a.GrouponOrderCount;
              break;
            case 4:
              return b.GrouponCount - a.GrouponCount;
              break;
            case 5:
              return b.NormalOrderAmount - a.NormalOrderAmount;
              break;
            case 6:
              return b.NormalOrderCount - a.NormalOrderCount;
              break;
            default:
              return b.TotalOrderAmount - a.TotalOrderAmount;
          }
        }
      );
      // this.getEmployeeStatis();
    },
  },
};
</script>

<style lang="less" scoped>
.empl-group {
  .df-row {
    margin-block-start: 20px;
  }

  .el-table {
    .image {
      .el-image {
        width: 60px;
        height: 60px;
      }
    }

    .groupon-count,
    .order-amount,
    .order-count {
      .flex-row;
      justify-content: center;
      width: 100%;

      > div {
        .flex-row;
        justify-content: center;
        margin: 0 10px;
        cursor: pointer;

        .icon-box {
          .flex-col;
          margin-left: 2px;
          color: #ddd;
          font-size: 14px;

          i {
            line-height: 0;
            padding: 3.5px 0;
          }
        }
      }

      .sort-active {
        color: #409eff;
      }
    }
  }
}
</style>
